import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface ModalObjectModel {
  title: string;
  description: string;
  data: any;
  buttons?: ModalButtonModel[];
}

interface ModalButtonModel {
  text: string;
  class: string;
  callBack?: Subject<any>;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public title$ = new Subject<string>();
  public description$ = new Subject<string>();
  public show$ = new Subject<boolean>();
  public data$ = new Subject<any>();
  public buttons$ = new Subject<ModalButtonModel[]>();

  public riseMessage(config: ModalObjectModel): void {
    this.title$.next(config.title);
    this.description$.next(config.description);
    this.data$.next(config.data);
    this.show$.next(true);
    this.buttons$.next(config.buttons);
  }
}
