import { ActivatePasswordComponent } from './pages/login/components/activate-password/activate-password.component';
import {
  Platform,
  NavController,
  MenuController
} from '@ionic/angular';
import {
  Component,
  HostListener,
  OnInit
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Router,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications
} from '@capacitor/push-notifications';
import { AlertController } from '@ionic/angular';
import { Network } from '@capacitor/network';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public networkConnection: Boolean;
  public networkType: string;
  public networkChangeCount: number = 0;

  title = '';

  //boolean
  mobile: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private platform: Platform,
    private navController: NavController,
    private alertController: AlertController,
    private menuController: MenuController
  ) {
    this.mobile =
      Capacitor.getPlatform() === 'ios' ||
      Capacitor.getPlatform() === 'android' ||
      Capacitor.getPlatform() !== 'web';
    console.log(
      '🚀 ~ file: app.component.ts:39 ~ AppComponent ~ this.mobile',
      this.mobile
    );

    const width = this.platform.width();
    this.toggleMenu(width);

    if (this.platform.is('capacitor')) {
      this.pushNotificationHandler();
    }
  }

  ngAfterViewInit() {}

  ngOnInit() {
    let handler = Network.addListener(
      'networkStatusChange',
      (status) => {
        console.log(
          'CESAR Network status changed',
          JSON.stringify(status)
        );
        if (
          this.networkConnection != status.connected &&
          this.networkType != status.connectionType
        ) {
          console.log(
            'CESAR NETWORK nothing change in network'
          );
          this.networkChangeCount++;
          this.networkConnection = status.connected;
          this.networkType = status.connectionType;
          if (this.networkChangeCount >= 5) {
            this.networkStatusMultipleChanges();
          } else {
            if (status.connected == false) {
              this.networkStatusChangeDisconnectAdvise(
                status
              );
            } else {
              this.networkStatusChangeConnectAdvise(status);
            }
          }
        } else {
          console.log(
            'CESAR NETWORK nothing change in network'
          );
        }
      }
    );

    this.getNetworkStatus();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data) => {
          this.titleService.setTitle(data.title);
        });
      });
    // tslint:disable-next-line:only-arrow-functions
    this.router.routeReuseStrategy.shouldReuseRoute =
      function () {
        return false;
      };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
  }

  async getNetworkStatus() {
    // Get the current network status
    let status = await Network.getStatus();
    console.log('CESAR GETNETWORK', JSON.stringify(status));
    this.networkConnection = status.connected;
    this.networkType = status.connectionType;
    if (status.connected == false) {
      this.networkDisconnectAdvise();
    }
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  pushNotificationHandler() {
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('ACTION PERFORMED    ' + notification);
        this.router.navigate(['avisos-app']);
      }
    );
  }

  async networkDisconnectAdvise() {
    const alert = await this.alertController.create({
      cssClass: 'alert-unavailable-device',
      header: 'Sin conexión',
      message:
        'El dispositivo no se encuentra conectado a la red, conéctelo para poder inicar sesión.',
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {}
        }
      ]
    });
    await alert.present();
  }

  async networkStatusChangeConnectAdvise(status) {
    const alert = await this.alertController.create({
      cssClass: 'alert-unavailable-device',
      header: 'Conexión restablecida',
      message:
        'El dispositivo se ha conectado a una red de tipo ' +
        this.networkType +
        ', ya puede iniciar sesión.',
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {}
        }
      ]
    });
    await alert.present();
  }

  async networkStatusChangeDisconnectAdvise(status) {
    const alert = await this.alertController.create({
      cssClass: 'alert-unavailable-device',
      header: 'Sin conexión',
      message:
        'El dispositivo se ha desconectado de la red, será redirigido a la pantalla de login hasta que se reestablezca la conexión.',
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            this.router.navigate(['login-app']);
          }
        }
      ]
    });
    await alert.present();
  }

  async networkStatusMultipleChanges() {
    const alert = await this.alertController.create({
      cssClass: 'alert-unavailable-device',
      header: 'Conexión inestable',
      message:
        'Se han detectado múltiples cambios de red durante la ejecución de la aplicación, esto puede provocar lentitud y fallos. Por favor, revise la configuración de red de su dispositivo.',
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {}
        }
      ]
    });
    await alert.present();
  }

  setTitle(title) {
    this.title = title;
  }

  toggleMenu(width) {
    if (width > 768) {
      this.menuController.enable(false, 'myMenu');
    } else {
      this.menuController.enable(
        !this.mobile ? true : false,
        'myMenu'
      );
    }
  }

  @HostListener('window: resize', ['$event'])
  private onResize(event) {
    const newWidth = event.target.innerWidth;
    this.toggleMenu(newWidth);
  }
}
