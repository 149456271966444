import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalService } from '../../services/config-dialog.service';

interface ModalButtonModel {
  text: string;
  callBack?: Subject<boolean>;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public show = false;
  public title = 'Atención';
  public description: string;
  public data: any;
  public buttons: ModalButtonModel[];

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.modalService.show$.subscribe((show: boolean) => {
      if (show) {
        this.show = show;
      } else {
        setTimeout(() => (this.show = show), 300);
      }
    });

    this.modalService.buttons$.subscribe(
      (buttons: ModalButtonModel[]) => {
        setTimeout(() => {
          this.buttons = buttons;
        }, 0);
      }
    );

    this.modalService.title$.subscribe((title: string) => {
      setTimeout(() => {
        this.title = title;
      }, 0);
    });

    this.modalService.description$.subscribe(
      (description: string) => {
        setTimeout(() => {
          this.description = description;
        }, 0);
      }
    );

    this.modalService.data$.subscribe((data: any) => {
      setTimeout(() => {
        this.data = data;
      }, 0);
    });
  }

  public callbackButton(button?: Subject<any>): void {
    if (button) {
      button.next(this.data);
    }
    this.show = false;
  }
}
