<ion-menu
  class="movsa-menu"
  contentId="main-myMenu"
  menuId="myMenu"
  side="start"
  [disabled]="mobile"
>
  <ion-header class="movsa-menu-header">
    <ion-toolbar>
      <img
        class="movsa-menu-img"
        src="assets/images/profile_img.png"
      />
      <ion-title class="movsa-menu-name">{{
        user?.firstName + ' ' + user?.lastName
      }}</ion-title>
      <ion-menu-toggle>
        <ion-item
          lines="none"
          button
          (click)="goToRoute('/perfil-app')"
        >
          <p class="movsa-menu-profile">Perfil</p>
        </ion-item>
      </ion-menu-toggle>
    </ion-toolbar>
  </ion-header>
  <ion-content class="movsa-menu-content">
    <div class="padding-top-search">
      <div *ngIf="roleProfessional" class="search">
        <div (click)="showList = true" class="select-bar">
          <p class="modal-select-text">
            {{ residenceHolder }}
          </p>
          <img
            class="show-more-img"
            src="assets/images/show_more.svg"
            alt=""
          />
        </div>
        <ion-list
          *ngIf="showList"
          class="modal-list"
          lines="none"
        >
          <ion-item
            *ngFor="let residence of residences"
            (click)="
              selectItem(residence.id, residence.name)
            "
          >
            <p class="modal-select-text">
              {{ residence.name }}
            </p>
          </ion-item>
        </ion-list>
      </div>

      <div *ngIf="roleProfessional" class="search">
        <ion-searchbar
          #search
          placeholder="Elige un usuario"
          type="text"
          debounce="500"
          [(ngModel)]="searchboxValue2"
          (ionChange)="getAllResidents($event)"
        >
        </ion-searchbar>
        <ion-list
          #list
          class="search-list"
          *ngIf="isItemAvailable2"
          lines="none"
        >
          <ion-item
            *ngFor="let res of items2"
            (click)="clickResident(res)"
            class="search-list-item"
            >{{ res.name }} {{ res.firstName }}</ion-item
          >
        </ion-list>
      </div>

      <div *ngIf="multipleRelatives" class="search">
        <div (click)="showList = true" class="select-bar">
          <p class="modal-select-text">
            {{ residentHolder }}
          </p>
          <img
            class="show-more-img"
            src="assets/images/show_more.svg"
          />
        </div>

        <ion-list
          *ngIf="showList"
          class="modal-list"
          lines="none"
        >
          <ion-item
            *ngFor="let resident of familyResidents"
            (click)="selectFamily(resident)"
          >
            <p class="modal-select-text">
              {{ resident.name }} {{ resident.firstName }}
            </p>
          </ion-item>
        </ion-list>
      </div>
    </div>
    <ion-list lines="none" class="movsa-menu-items">
      <ion-menu-toggle>
        <ion-item
          [attr.disabled]="!activateResidentProfile"
          class="movsa-menu-item"
          (click)="goToRoute('/perfil-monitorizado-app')"
        >
          <ion-icon
            class="movsa-menu-icon"
            name="person-circle-outline"
          ></ion-icon>
          <ion-label class="movsa-menu-label">{{
            residentProfile
          }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item (click)="goToRoute('/contacto-app')">
          <ion-icon
            class="movsa-menu-icon"
            name="call-outline"
          ></ion-icon>
          <ion-label class="movsa-menu-label"
            >Contactar con el centro</ion-label
          >
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item
          [attr.disabled]="eventsDisabled"
          class="movsa-menu-item"
          (click)="goToRoute('/eventos-app')"
        >
          <img
            class="movsa-menu-icon events-icon"
            src="assets/images/events_ic.svg"
          />
          <ion-label class="movsa-menu-label"
            >Eventos</ion-label
          >
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item
          class="movsa-menu-item"
          (click)="goToRoute('/configuracion-app')"
        >
          <ion-icon
            class="movsa-menu-icon"
            name="settings-outline"
          ></ion-icon>
          <ion-label class="movsa-menu-label"
            >Configuración</ion-label
          >
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item
          class="movsa-menu-item"
          (click)="showCloseSession()"
        >
          <ion-icon
            class="movsa-menu-icon"
            name="log-out-outline"
          ></ion-icon>
          <ion-label class="movsa-menu-label"
            >Cerrar sesión</ion-label
          >
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
  </ion-content>
</ion-menu>
