import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  UntypedFormControl,
  FormGroup
} from '@angular/forms';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { IonMenu, MenuController } from '@ionic/angular';
import { MenuWorkService } from 'src/app/pages/menu-work/services/menu-work.service';
import { ResidenceInterface } from 'src/app/shared/interfaces/residenceInterface';
import { ResidentInterface } from 'src/app/shared/interfaces/residentInterface';
import { UserInterface } from 'src/app/shared/interfaces/userInterface';
import { IdService } from 'src/app/shared/services/id.service';
import { RelativeService } from 'src/app/shared/services/relative.service';
import { Storage } from '@capacitor/storage';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  //ViewChild
  @ViewChild('menu', { static: false }) menu: IonMenu;

  //boolean
  public menuIsOpen: boolean = true;
  public roleFamily: boolean;
  public activateResidentProfile: boolean;
  public multipleRelatives: boolean;
  public preLoading: boolean = true;
  public roleProfessional: boolean = false;
  public showList: boolean;
  public isItemAvailable2: boolean = false;
  public eventsDisabled: boolean = true;
  public showModal: boolean = false;
  public showModalCloseSession: boolean = false;

  //UserInterface
  public user: UserInterface;

  //string
  public residentProfile = 'Perfil Usuario monitorizado';
  public residentHolder = '';
  private centerId: string;
  public residenceHolder: string;
  public searchboxValue2 = '';

  ///FormGroup
  searchForm: UntypedFormControl = new UntypedFormControl(
    ''
  );

  //array
  public familyResidents: Array<ResidentInterface> = [];
  public residences: Array<ResidenceInterface> = [];
  public allResidents: Array<ResidentInterface> = [];
  public items2: Array<ResidentInterface> = [];

  //boolean
  mobile: boolean;

  constructor(
    private readonly menuWorkService: MenuWorkService,
    private router: Router,
    private idService: IdService,
    private relativeService: RelativeService
  ) {
    this.mobile =
      Capacitor.getPlatform() === 'ios' ||
      Capacitor.getPlatform() === 'android' ||
      Capacitor.getPlatform() !== 'web';
    console.log(
      '🚀 ~ file: app.component.ts:39 ~ AppComponent ~ this.mobile',
      this.mobile
    );
  }

  ngOnInit() {
    console.log(
      '🚀 ~ file: menu.component.ts:68 ~ ionViewWillEnter ~ ionViewWillEnter'
    );
    this.menuWorkService.getAccount().subscribe(
      async (user: UserInterface) => {
        this.user = user;
        console.log(
          '🚀 ~ file: menu.component.ts:71 ~ ionViewWillEnter ~ this.user',
          this.user
        );

        if (this.user.authorities.includes('ROLE_FAMILY')) {
          this.residentProfile = 'Perfil de tu familiar';
          this.roleFamily = true;
          this.activateResidentProfile = true;
          if (user.resident.length > 2) {
            this.multipleRelatives = true;
          }

          this.familyResidents = user.resident;
          this.residentHolder =
            this.familyResidents[0].name +
            ' ' +
            this.familyResidents[0].firstName;
        }

        await Storage.get({ key: 'center' }).then(
          (center) => {
            this.roleProfessional = true;
            console.log(
              '🚀 ~ file: menu.component.ts:86 ~ MenuComponent ~ Storage.get ~ this.roleProfessional',
              this.roleProfessional
            );
            this.centerId = center.value;
            this.residences = [];
            for (const i of user.residence) {
              const idString = i.id.toString();
              this.menuWorkService
                .getResidence(idString)
                .subscribe(
                  async (residence: ResidenceInterface) => {
                    this.residences.push(residence);
                    this.residenceHolder =
                      this.residences[0].name;
                  }
                );
            }

            this.menuWorkService
              .getAllResidentsByResidence(this.centerId)
              .subscribe(
                (residents: Array<ResidenceInterface>) => {
                  this.allResidents = residents;
                }
              );
          }
        );
      },
      (error) => {
        this.router.navigate(['login-app']);
      }
    );

    setTimeout(() => {
      this.preLoading = false;
    }, 2000);
  }

  public showCloseSession(): void {
    this.menu.close();
    this.showModal = true;
    this.showModalCloseSession = true;
  }

  public goToRoute(route: string): void {
    this.router.navigate([route]);
  }

  public async selectItem(id: number, name: string) {
    this.residenceHolder = name;
    this.showList = false;
    this.menuWorkService
      .getResidence(id.toString())
      .subscribe(async (residence: ResidenceInterface) => {
        this.residenceHolder = residence.name;
      });
  }

  public getAllResidents(event: any) {
    var val = event.target.value;
    console.log(
      '🚀 ~ file: menu.component.ts:136 ~ val',
      val
    );

    if (val && val.trim() !== '' && val.length > 2) {
      this.items2 = this.allResidents.filter((resident) => {
        const searchResident = (
          resident?.name +
          ' ' +
          resident?.firstName
        )
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        return (
          searchResident
            .toLowerCase()
            .indexOf(val.toLowerCase()) > -1
        );
      });

      console.log(
        '🚀 ~ file: menu.component.ts:147 ~ getAllResidents ~ this.searchboxValue2',
        this.searchboxValue2
      );
      if (val !== this.searchboxValue2) {
        this.isItemAvailable2 = true;
      }
    } else if (val === '') {
      this.isItemAvailable2 = false;
      this.activateResidentProfile = false;
      this.searchboxValue2 = '';
    } else {
      this.isItemAvailable2 = false;
    }
  }

  public clickResident(item) {
    if (
      this.searchboxValue2 !==
      item.name + ' ' + item.firstName
    ) {
      this.idService.changeId(item.id.toString());
    }

    this.searchForm.setValue(
      item.name + ' ' + item.firstName
    );
    //this.searchboxValue2 = item.name + " " + item.firstName;
    this.isItemAvailable2 = false;
    this.activateResidentProfile = true;
  }

  public selectFamily(resident: ResidentInterface) {
    this.residentHolder =
      resident.name + ' ' + resident.firstName;
    this.showList = false;
    this.idService.changeId(resident.id.toString());
    this.relativeService.changeRelative(resident);
    this.ngOnInit();
  }
}
