<ion-content *ngIf="show">
  <div class="overlay"></div>
  <div class="modal">
    <img
      src="assets/images/web/esquina.svg"
      alt="Esquina"
    />
    <h2>{{ title }}</h2>
    <p>
      {{ description }}
    </p>
    <button
      [class]="button.class"
      *ngFor="let button of buttons"
      (click)="callbackButton(button?.callBack)"
    >
      {{ button.text }}
    </button>
  </div>
</ion-content>
