import { Injectable } from '@angular/core';

@Injectable()
export class IdService {
  private id = '';

  constructor() {}

  changeId(id: string) {
    this.id = id;
  }

  resetId() {
    this.id = '';
  }

  getId(): string {
    return this.id;
  }
}
