import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FiltersService {
  private filters = [];
  private filtersLive = [];

  constructor() {}

  addFilters(filters: Array<string>) {
    this.filters = [];
    for (const filter of filters) {
      this.filters.push(filter);
    }
  }

  resetFilters() {
    this.filters = [];
  }

  getFilters() {
    return this.filters;
  }

  addFiltersLive(filters: Array<string>) {
    this.filtersLive = [];
    for (const filter of filters) {
      this.filtersLive.push(filter);
    }
  }

  resetFiltersLive() {
    this.filtersLive = [];
  }

  getFiltersLive() {
    return this.filtersLive;
  }
}
