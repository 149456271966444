import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthService } from '../../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(
    public platform: Platform,
    private router: Router,
    private authService: AuthService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.platform.ready().then(() => {
      if (this.platform.is('desktop')) {
        this.authService.isLogged().then((val) => {
          if (!val.value) {
            this.router.navigate(['/login']);
            return false;
          } else {
            this.router.navigate(['/perfil']);
            return true;
          }
        });
      } else {
        return true;
      }
    });
  }
}
