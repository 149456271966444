import { Session } from 'src/app/shared/interfaces/session';
import { API } from '../../../global/api';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuWorkService {
  constructor(private http: HttpClient) {}

  public getAccount(): Observable<any> {
    return this.http.get(API.GET_ACCOUNT);
  }

  public searchResidents(
    page: number,
    size: number,
    residentSearch
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page.toString(),
        size: size.toString()
      }
    });
    return this.http.post(
      API.SEARCH_RESIDENT + '?' + params,
      residentSearch
    );
  }

  public getUser(login: string): Observable<any> {
    return this.http.get(
      API.GET_USER.replace('{login}', login)
    );
  }

  public getSession(id: string): Observable<any> {
    return this.http.get(
      API.GET_SESSION.replace('{id}', id)
    );
  }

  public getDataSession(id: string): Observable<any> {
    return this.http.get(
      API.GET_DATA_SESSION.replace('{id}', id)
    );
  }

  public getDataSessionsByResidence(
    endDate: string,
    initDate: string,
    residenceId: string,
    page,
    size
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        endDate: endDate,
        idResidence: residenceId,
        initDate: initDate,
        page: page,
        size: size
      }
    });
    return this.http.get(
      API.GET_DATA_SESSION_FILTERS + '?' + params
    );
  }

  public getDataSessionsByResident(
    endDate: string,
    initDate: string,
    resident: string,
    limit: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        endDate: endDate,
        idResident: resident,
        initDate: initDate,
        idResidence: limit,
        page: '0',
        size: '99999999'
      }
    });
    return this.http.get(
      API.GET_DATA_SESSION_FILTERS + '?' + params
    );
  }

  public getAllSessions(
    endDate: string,
    initDate: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        endDate: endDate,
        initDate: initDate,
        resident: id
      }
    });
    return this.http.get(
      API.GET_ALL_SESSIONS + '?' + params
    );
  }

  public getAllDataSessions(
    endDate: string,
    initDate: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        endDate: endDate,
        initDate: initDate,
        resident: id
      }
    });
    return this.http.get(
      API.GET_ALL_DATA_SESSIONS + '?' + params
    );
  }

  public updateSession(session: Session): Observable<any> {
    return this.http.put(API.UPDATE_SESSION, session);
  }

  public getAllResidentsByResidence(
    id: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: '0',
        size: '10000'
      }
    });
    return this.http.get(
      API.GET_ALL_RESIDENTS_BY_RESIDENCE.replace(
        '{idResidence}',
        id
      ) +
        '?' +
        params
    );
  }

  public getResidence(id: string): Observable<any> {
    return this.http.get(
      API.GET_RESIDENCE.replace('{id}', id)
    );
  }

  public getResident(id: string): Observable<any> {
    return this.http.get(
      API.GET_RESIDENT.replace('{id}', id)
    );
  }

  public getAllTemperatures(
    initDate: string,
    endDate: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        endDate: endDate,
        initDate: initDate,
        // page: '0',
        resident: id,
        size: '999999'
      }
    });
    return this.http.get(
      API.GET_ALL_TEMPERATURES + '?' + params
    );
  }

  public getAllHeartRate(
    initDate: string,
    endDate: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        endDate: endDate,
        initDate: initDate,
        // page: '0',
        resident: id,
        size: '999999'
      }
    });
    return this.http.get(
      API.GET_ALL_HEART_RATES + '?' + params
    );
  }

  public getAllArrhythmia(
    initDate: string,
    endDate: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        endDate: endDate,
        initDate: initDate,
        // page: '0',
        resident: id,
        size: '999999'
      }
    });
    return this.http.get(
      API.GET_ALL_ARRHYTHMIAS + '?' + params
    );
  }

  public getAllBloodPressure(
    initDate: string,
    endDate: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        initDate: initDate,
        endDate: endDate,
        // page: '0',
        resident: id,
        size: '999999'
      }
    });
    console.log(
      'CESAR PRESSURE PARAMS ',
      JSON.stringify(params),
      ' DATE END ',
      endDate,
      ' DATE INIT ',
      initDate,
      ' RESIDENT ',
      id
    );
    return this.http.get(
      API.GET_ALL_BLOOD_PRESSURES + '?' + params
    );
  }

  public getAllOxigenSaturation(
    initDate: string,
    endDate: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        initDate: initDate,
        endDate: endDate,
        // page: '0',
        resident: id,
        size: '999999'
      }
    });
    return this.http.get(
      API.GET_ALL_OXIGEN_SATURATIONS + '?' + params
    );
  }

  public getAllWeights(
    initDate: string,
    endDate: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        initDate: initDate,
        endDate: endDate,
        // page: '0',
        resident: id,
        size: '999999'
      }
    });
    return this.http.get(
      API.GET_ALL_WEIGHTS + '?' + params
    );
  }

  public getAllECGOnline(id: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        resident: id
      }
    });
    return this.http.get(
      API.GET_ALL_ECG_ONLINE + '?' + params
    );
  }

  public updateEcgOnline(
    seconds: number,
    residentId: number
  ): Observable<any> {
    const body = {
      seconds: seconds,
      resident: residentId
    };
    return this.http.put(API.UPDATE_ECG_ONLINE, body);
  }

  public searchDataSession(
    page: number,
    size: number,
    searchDataSession
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page.toString(),
        size: size.toString()
      }
    });
    return this.http.post(
      API.SEARCH_DATA_SESSION + '?' + params,
      searchDataSession
    );
  }

  getAdvisesDiary(dataSession): Observable<any> {
    return this.http.post(
      API.GET_ADVISES_DIARY,
      dataSession
    );
  }
}
