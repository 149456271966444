import { AuthGuard } from './guard/auth.guard';
import { MobilePlatformGuard } from './guard/mobile-platform.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';
import { RedirectGuard } from './guard/redirect.guard';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { IonicModule } from '@ionic/angular';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { AuthService } from '../shared/services/auth.service';
import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { HeaderResponsiveComponent } from './components/header-responsive/header-responsive.component';
import { MenuComponent } from './components/menu/menu.component';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

@NgModule({
  declarations: [
    LoadingComponent,
    HeaderComponent,
    FooterComponent,
    ConfirmDialogComponent,
    HeaderResponsiveComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    LoadingComponent,
    ConfirmDialogComponent,
    HeaderResponsiveComponent,
    MenuComponent
  ],
  providers: [
    AuthGuard,
    MobilePlatformGuard,
    RedirectGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {}
